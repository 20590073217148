import { useEffect, useRef, useState } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import TabNavigation, {
  LabelForTabs,
} from "../../components/reusable/TabNavigation";
import ActiveUsersTable from "../../components/tables/customer-management/active-users-table/ActiveUsersTable";
import BlockedUsersTable from "../../components/tables/customer-management/blocked-users-table/BlockedUsersTable";
import { Modal, message } from "antd";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import addTrip from "../trip-management/assets/add-trip.png";
import edit from "../../assets/edit_pencil.svg";
import profile from "../../assets/profile_avatar.svg";
import Button from "../../components/reusable/Button";
import { validateFields } from "./validations/validate";
import {
  getDistricts,
  getStates,
} from "../../services/DriverManagementServices";
import axios from "axios";
import {
  customerCreateByAdmin,
  getAllCustomers,
  getCustomerCount,
} from "../../services/CustomerManagementServices";
import InactiveUsersTable from "../../components/tables/customer-management/inactive-users-table/InactiveUsersTable";

export default function CustomerManagement() {
  const [activeKey, setActiveKey] = useState("active-user");
  const [addCustomerModal, setAddCutomerModal] = useState(false);
  const [profileImage, setProfileImage] = useState(undefined);
  const [allCustomers, setAllCustomers] = useState({
    data: [],
    totalCount: 0,
  });
  const [customerCount, setCustomerCount] = useState({
    totalActiveCount: 0,
    totalBlockedCount: 0,
    totalInactiveCount: 0,
  });

  const profileImageRef = useRef();

  const [customer, setCustomer] = useState({
    profileImageUrl: "",
    customerName: "",
    customerPhoneNumber: "",
    customerEmail: "",
    customerState: {},
    customerDistrict: {},
    customerStreet: "",
    customerHouseNumber: "",
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  //Filters State
  const [filters, setFilters] = useState({
    customerId: "",
    customerType: "",
    fromDate: "",
    toDate: "",
  });

  //Pagination details
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [sortByColumn, setSortByColumn] = useState({});

  async function getAllCustomer(
    userStatus,
    page,
    rowsPerPage,
    customerId,
    customerType,
    fromDate,
    toDate
  ) {
    try {
      const response = await getAllCustomers(
        userStatus,
        page,
        rowsPerPage,
        customerId,
        customerType,
        fromDate,
        toDate,
        sortByColumn
      );
      setAllCustomers(response);
    } catch (err) {
      message.error(err.response.data.message || err.message);
    }
  }

  useEffect(() => {
    let userStatus;
    if (activeKey === "active-user") {
      userStatus = 1;
    } else if (activeKey === "inactive-user") {
      userStatus = 2;
    } else {
      userStatus = 0;
    }
    getAllCustomer(
      userStatus,
      page + 1,
      rowsPerPage,
      filters.customerId,
      filters.customerType.id,
      filters.fromDate,
      filters.toDate
    );
  }, [activeKey, page, rowsPerPage, filters, sortByColumn]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCount = async () => {
    try {
      const data = await getCustomerCount();
      setCustomerCount(data.data);
    } catch (err) {
      message.error(err.response.data.message || err.message);
    }
  };

  useEffect(() => {
    if (allCustomers.data.length > 0) {
      getCount();
    }
  }, [allCustomers.data]);

  const tabContents = [
    {
      key: "active-user",
      label: (
        <LabelForTabs isActive={activeKey === "active-user"}>
          Active User ({customerCount.totalActiveCount})
        </LabelForTabs>
      ),
      children: (
        <ActiveUsersTable
          customerData={allCustomers}
          activeKey={activeKey}
          rowsPerPage={rowsPerPage}
          rowCount={customerCount.totalActiveCount || 0}
          page={page}
          filters={filters}
          setFilters={setFilters}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          getAllCustomer={getAllCustomer}
          sortByColumn={sortByColumn}
          setSortByColumn={setSortByColumn}
        />
      ),
    },
    {
      key: "blocked-user",
      label: (
        <LabelForTabs isActive={activeKey === "blocked-user"}>
          Blocked User ({customerCount.totalBlockedCount})
        </LabelForTabs>
      ),
      children: (
        <BlockedUsersTable
          customerData={allCustomers}
          activeKey={activeKey}
          rowsPerPage={rowsPerPage}
          rowCount={customerCount.totalBlockedCount || 0}
          page={page}
          filters={filters}
          setFilters={setFilters}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          getAllCustomer={getAllCustomer}
          sortByColumn={sortByColumn}
          setSortByColumn={setSortByColumn}
        />
      ),
    },
    {
      key: "inactive-user",
      label: (
        <LabelForTabs isActive={activeKey === "inactive-user"}>
          Inactive User ({customerCount.totalInactiveCount})
        </LabelForTabs>
      ),
      children: (
        <InactiveUsersTable
          customerData={allCustomers}
          activeKey={activeKey}
          rowsPerPage={rowsPerPage}
          rowCount={customerCount.totalInactiveCount || 0}
          page={page}
          filters={filters}
          setFilters={setFilters}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          getAllCustomer={getAllCustomer}
          sortByColumn={sortByColumn}
          setSortByColumn={setSortByColumn}
        />
      ),
    },
  ];

  const handleProfileImage = (event) => {
    if (event) {
      setProfileImage(event.target.files[0]);
      uploadFiles(event, setCustomer);
    }
  };

  async function handleCreateUser() {
    const fields = {
      customerName: customer.customerName,
      customerPhoneNumber: customer.customerPhoneNumber,
      // customerEmail: customer.customerEmail
    };

    const errors = validateFields(fields);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const createUserPayload = {
        mobileNumber: customer.customerPhoneNumber.split(" ").join(""),
        userName: customer.customerName,
        email: customer.customerEmail,
        state: customer.customerState.stateName,
        district: customer.customerDistrict.districtName,
        houseNumber: customer.customerHouseNumber,
        street: customer.customerStreet,
        imageUrl: customer.profileImageUrl,
        userCreatedBy: 3, //Created by admin
      };
      try {
        let userStatus;
        if (activeKey === "active-user") {
          userStatus = 1;
        } else {
          userStatus = 0;
        }
        const response = await customerCreateByAdmin(createUserPayload);
        setLoading(false);
        message.success("Customer Created Sucessfully!");
        setAddCutomerModal(false);
        getAllCustomer(
          userStatus,
          page + 1,
          rowsPerPage,
          filters.customerId,
          filters.customerType,
          filters.fromDate,
          filters.toDate
        );
        resetForm();
      } catch (error) {
        setLoading(false);
        message.error(
          error.response.data.message ||
            error.message ||
            error.response.data.error
        );
      }
    }
  }

  function resetForm() {
    setCustomer({
      profileImageUrl: "",
      customerName: "",
      customerPhoneNumber: "",
      customerEmail: "",
      customerState: {},
      customerDistrict: {},
      customerStreet: "",
      customerHouseNumber: "",
    });
    setProfileImage(undefined);
    setErrors({});
  }

  async function getState() {
    try {
      const response = await getStates();
      if (response.data) {
        setStates(response.data);
      }
    } catch (err) {
      message.error(
        err.response.data.message || err.response.data.error || err.message
      );
    }
  }

  async function getAllDistrict(item) {
    try {
      const response = await getDistricts(item);
      if (response.data) {
        setDistricts(response.data);
      }
    } catch (err) {
      message.error(
        err.response.data.message || err.response.data.error || err.message
      );
    }
  }

  const uploadFiles = async (e, setFileUrls) => {
    const formData = new FormData();
    const files = e.target.files;
    formData.append("images", files[0]);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/driver/uploadDocument`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            email: localStorage.getItem("CC_ADMIN_EMAIL"),
            usertype: localStorage.getItem("CC_ADMIN_USERTYPE"),
          },
        }
      );

      if (response.data.status && response.data.data) {
        setFileUrls((prev) => ({
          ...prev,
          profileImageUrl: response.data.data[0],
        }));
      }
    } catch (error) {
      if (
        (error.response && error.response.status === 401) ||
        error.response.status === 403
      ) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  useEffect(() => {
    getState();
  }, []);

  //Reset filters on tab switch
  useEffect(() => {
    setFilters({
      customerId: "",
      customerType: "",
      fromDate: "",
      toDate: "",
    });
    setPage(0);
  }, [activeKey]);

  return (
    <>
      <PageLayout
        title={"Customer Management"}
        description={"Manage the customer details here"}
        buttonTitle={"Create Customer"}
        buttonWidth={"150px"}
        // BreadcrumbsUrl={BreadcrumbsUrl}
        setAddTripModal={setAddCutomerModal}
      >
        <div className=" w-full flex md:mt-7">
          <TabNavigation
            tab={"customerManagement"}
            tabContents={tabContents}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
          />
        </div>
      </PageLayout>
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
            <h1 className="pt-1 font-semibold">Create Customer</h1>
          </>
        }
        visible={addCustomerModal}
        width={"1000px"}
        onCancel={() => {
          resetForm();
          setAddCutomerModal(false);
        }}
        footer={null}
      >
        <div className="w-full h-fit py-5">
          {/* Profile Image */}
          <div className="flex flex-col items-center justify-center w-full">
            <div className="relative -top-4">
              {profileImage === undefined ? (
                <img
                  width={100}
                  src={profile}
                  alt="profile"
                  className="w-32 h-32"
                />
              ) : (
                <img
                  src={URL.createObjectURL(profileImage)}
                  alt="profile"
                  className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
                />
              )}
              <button
                className="absolute right-1 top-3"
                onClick={() => profileImageRef.current.click()}
              >
                <img src={edit} alt="" />
                <input
                  type="file"
                  className="hidden"
                  ref={profileImageRef}
                  onChange={handleProfileImage}
                  accept="image/*"
                />
              </button>
            </div>
          </div>
          <h3 className="font-semibold py-2 -mt-5">Personal Details</h3>
          <div className=" w-full flex-wrap gap-5  h-fit pb-5 flex justify-between items-center   ">
            <Input
              label={"Customer Name"}
              placeholder={"Enter customer name"}
              value={customer.customerName}
              onChange={(e) =>
                setCustomer((prev) => ({
                  ...prev,
                  customerName: e.target.value,
                }))
              }
              error={errors.customerName}
              mandate
            />
            <MobileNumberInput
              label={"Phone Number"}
              value={customer.customerPhoneNumber}
              setValue={(item) =>
                setCustomer((prev) => ({ ...prev, customerPhoneNumber: item }))
              }
              error={errors.customerPhoneNumber}
              mandate
            />
            <Input
              label={"Email"}
              placeholder={"Enter customer email"}
              // error={errors.email}
              onChange={(e) =>
                setCustomer((prev) => ({
                  ...prev,
                  customerEmail: e.target.value,
                }))
              }
              value={customer.customerEmail}
              // mandate
            />
          </div>
          <h3 className="font-semibold py-2">Address Details</h3>
          <div className=" w-full flex-wrap gap-5  h-fit pb-5 flex justify-between items-center   ">
            <DropDown
              label={"State"}
              placeholder={"Select state"}
              data={states}
              displayValue={"stateName"}
              value={customer.customerState}
              setValue={(item) => {
                setCustomer((prev) => ({ ...prev, customerState: item }));
                getAllDistrict(item.id);
              }}
            />
            <DropDown
              label={"District"}
              placeholder={"Select district"}
              data={districts}
              displayValue={"districtName"}
              value={customer.customerDistrict}
              setValue={(item) =>
                setCustomer((prev) => ({ ...prev, customerDistrict: item }))
              }
            />
            <Input
              label={"Street"}
              placeholder={"Enter street name"}
              value={customer.customerStreet}
              onChange={(e) =>
                setCustomer((prev) => ({
                  ...prev,
                  customerStreet: e.target.value,
                }))
              }
            />
            <Input
              label={"House Number"}
              placeholder={"Enter house number"}
              value={customer.customerHouseNumber}
              onChange={(e) =>
                setCustomer((prev) => ({
                  ...prev,
                  customerHouseNumber: e.target.value,
                }))
              }
            />
          </div>
          <div className=" w-full flex  justify-center items-center">
            <Button
              text={"Create Customer"}
              width={"auto"}
              loading={loading}
              onClick={handleCreateUser}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
