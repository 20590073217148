import axios from "axios";

export const customerLoginByAdmin = async (mobileNumber) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/customer/customerLoginByAdmin`,
      { mobileNumber: mobileNumber },
      {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          email: localStorage.getItem("CC_ADMIN_EMAIL"),
          usertype: localStorage.getItem("CC_ADMIN_USERTYPE"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};

export const getCustomerByMobileNumber = async (mobileNumber) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/customer/getCustomerByMobileNumber?mobileNumber=${mobileNumber}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          email: localStorage.getItem("CC_ADMIN_EMAIL"),
          usertype: localStorage.getItem("CC_ADMIN_USERTYPE"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};

export const updateCustomer = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/customer/updateCustomerByAdmin`,
      { ...data },
      {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          email: localStorage.getItem("CC_ADMIN_EMAIL"),
          usertype: localStorage.getItem("CC_ADMIN_USERTYPE"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};

export const customerCreateByAdmin = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/customer/customerCreateByAdmin`,
      { ...data },
      {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          email: localStorage.getItem("CC_ADMIN_EMAIL"),
          usertype: localStorage.getItem("CC_ADMIN_USERTYPE"),
        },
      }
    );
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};

export const getAllCustomers = async (
  userStatus,
  page,
  rowsPerPage,
  customerId,
  customerType,
  fromDate,
  toDate,
  sortByColumn
) => {
  try {
    var queryParams = `?userStatus=${userStatus}&currentPage=${page}&pageSize=${rowsPerPage}`;

    if (customerId) {
      queryParams += `&searchQuery=${customerId}`;
    }

    if (customerType) {
      queryParams += `&userCreatedBy=${customerType}`;
    }

    if (fromDate) {
      queryParams += `&fromDate=${fromDate}`;
    }

    if (toDate) {
      queryParams += `&toDate=${toDate}`;
    }
    if (sortByColumn) {
      const [columnKey] = Object.keys(sortByColumn);
      const orderType = sortByColumn[columnKey];

      if (columnKey && orderType) {
        queryParams += `&sortByColumn=${columnKey}`;
        queryParams += `&orderType=${orderType}`;
      }
    }
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/customer/getAllCustomers${queryParams}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          email: localStorage.getItem("CC_ADMIN_EMAIL"),
          usertype: localStorage.getItem("CC_ADMIN_USERTYPE"),
        },
      }
    );
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};

export const getCustomerCount = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/customer/getCustomerCount`,
      {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          email: localStorage.getItem("CC_ADMIN_EMAIL"),
          usertype: localStorage.getItem("CC_ADMIN_USERTYPE"),
        },
      }
    );
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};

export const accountDeletionRequest = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/customer/deletionRequest`,
      { ...data },
      {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          email: localStorage.getItem("CC_ADMIN_EMAIL"),
          usertype: localStorage.getItem("CC_ADMIN_USERTYPE"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};
